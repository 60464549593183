<template>
    <div class="subscribe-article-container">
        <div class="subscribe-article">
            <div class="subscribe-article__content">
                <div class="subscribe-article__title">
                    <Translate translationKey="subscribe.newsletterArticleTitle"
                               fallback="Thirsty for Crypto Knowledge?"/>
                </div>
                <div class="subscribe-article__subtitle">
                    <Translate translationKey="subscribe.newsletterArticleSubtitle"
                               fallback="Get crypto-smart in just 5 minutes a day. Join 70,000+ subscribers enjoying our Daily Squeeze of jargon-free news and insights."/>
                </div>
                <form class="subscribe-article__form"
                      @submit.prevent="loadRecaptchaAndProceed">
                    <input v-model="email"
                           class="subscribe-article__input"
                           :class="{ 'subscribe-article__input--error': errorMessage }"
                           :placeholder="getTrans('subscribe.inputPlaceholder', 'Enter email here')"
                           name="email"
                           type="email">
                    <div v-if="errorMessage"
                         class="subscribe-article__error">
                        {{ errorMessage }}
                    </div>
                    <button class="subscribe-article__button quiz-button--correct"
                            type="submit">
                        <Translate translationKey="subscribe.subscribeIconText"
                                   fallback="Subscribe"/>
                    </button>
                </form>
            </div>
            <div v-if="!isMobileOrTablet"
                 class="subscribe-article__image-container">
                <img class="subscribe-article__image"
                     :src="asset_cdn('/assets/daily-squeeze-image.png')"
                     :alt="getTrans('subscribe.newsletterArticleTitle', 'Thirsty for Crypto Knowledge?')">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { asset_cdn } from '@/js/helpers/helpers';
    import { useSubscribeForm } from '@/js/subscribe/composables/useSubscribeForm';
    import { subscriberSourceEnum } from '@/js/enums/subscriberSourceEnum';
    import Translate from '@/js/helpers/Translate.vue';
    import { getTrans } from '@/js/helpers/getTrans';
    import { useDeviceSize } from '@/js/composables/useDeviceSize';

    const { isMobileOrTablet } = useDeviceSize();

    const SUBSCRIBE_FORM_BUTTON_ID = 'subscribe-form-proceed-button';
    const {
        email,
        errorMessage,
        loadRecaptchaAndProceed,
    } = useSubscribeForm(subscriberSourceEnum.SOURCE_NEWSLETTER, SUBSCRIBE_FORM_BUTTON_ID, true);
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";
@import "@/sass/partials/variables";

.subscribe-article-container {
    display: flex;
    width: 100%;
    justify-content: center;
    border-radius: 16px;
    background-color: $blue-solitude;
}

.subscribe-article {
    position: relative;
    display: flex;
    max-width: 800px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 52px 32px 60px;
    gap: 40px;
    text-align: center;

    @media (min-width: $md-breakpoint) {
        flex-direction: row;
        gap: 32px;
        text-align: left;
    }

    &::after {
        position: absolute;
        top: -30px;
        left: 0;
        width: 70px;
        height: 77px;
        background-image: url($asset-cdn-root + "/assets/daily-squeeze-image.png");
        background-repeat: no-repeat;
        background-size: cover;
        content: "";

        @media (min-width: $md-breakpoint) {
            display: none;
        }
    }

    &__title {
        margin-top: 10px;
        margin-bottom: 10px;
        color: $baseBlack;
        font-size: 28px;
        font-weight: 700;
        line-height: 1;
    }

    &__subtitle {
        margin-bottom: 16px;
        color: $baseBlack;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;

        @media (min-width: $sm-breakpoint) {
            font-size: 18px;
        }
    }

    &__form {
        position: relative;
    }

    &__input {
        width: 100%;
        height: 44px;
        padding: 6px 12px;
        border: 1px solid $baseBlack;
        border-radius: 10px;
        margin-bottom: 24px;
        background: $baseWhite;
        font-size: 16px;
        outline: none;

        &--error {
            margin-bottom: 32px;
        }
    }

    &__error {
        position: absolute;
        top: 50px;
        color: $error600;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
    }

    &__button {
        padding: 8px 40px;
    }

    &__image-container {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-end;
    }

    &__image {
        width: 150px;
        height: 150px !important;
        border: none !important;
    }
}
</style>

<style lang="scss">
.news-page {
    .subscribe-article::after {
        left: 15px;
    }
}
</style>
